<!--
 * @Description: 客服用户服务管理 意见反馈管理  意见反馈管理 feedBackInfoManage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 11:41:18
 * @LastEditors: hope0928 houjinduo1007@163.com
-->
<template>
  <div class="mainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="意见状态">
              <el-select v-model="searchForm.feedbackStatusCode"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in stateList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="意见类型">
              <el-select v-model="searchForm.feedbackType"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in typeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="创建时间">
              <el-date-picker v-model="noticeDate"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="queryButton">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>意见反馈管理</span>
         <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <!-- <template slot="operating">
            <el-table-column label="图片">
              <template slot-scope="scope">
                {{tableList.list[scope.$index].picUrl | filteImg}}
              </template>
            </el-table-column>
          </template> -->
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             @click="detailsClick(scope.row)">详情</el-button>
                </el-col>
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             @click="editClick(scope.row)">修改</el-button>
                </el-col>
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             @click="deleteClick(scope.row)">删除</el-button>
                </el-col>
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             v-show="scope.row.feedbackStatusDesc === '未处理'"
                             @click="dealClick(scope.row)">处理</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 处理反馈弹框 -->
    <el-dialog title="处理反馈"
               :visible.sync="feedBackDialogVisible"
               append-to-body
               :close-on-click-modal="false"
               @close="popClose">
      <el-form label-width="80px"
               :model="feedBackForm"
               :rules="rules"
               ref="feedBackForm"
               size="small">
        <el-row>
          <el-form-item label="处理反馈"
                        prop="handleResult">
            <el-input type="textarea"
                      style="width:95%"
                      :autosize="{ minRows: 4, maxRows: 8}"
                      v-model="feedBackForm.handleResult"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="feedbackSubmit('feedBackForm')"
                     style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">处 理</el-button>
          <el-button @click="feedBackDialogVisible = false"
                     type="info">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 修改、详情反馈弹框 -->
    <el-dialog :title="dialogTitle"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               append-to-body>
      <!-- 修改反馈表单 -->
      <el-form label-width="150px"
               :model="popForm"
               ref="popForm"
               :rules="rules"
               :inline="true"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="反馈类型"
                          prop="feedbackType">
              <el-select v-model="popForm.feedbackType"
                         disabled
                         class="dt-form-width"
                         placeholder="请选择">
                <el-option v-for="item in typeList"
                           :key="Number(item.code)"
                           :label="item.name"
                           :value="Number(item.code)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactWay">
              <el-input v-model="popForm.concat"
                        readonly
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="反馈描述"
                          prop="feedbackDescription">
              <el-input v-model="popForm.content"
                        readonly
                        type="textarea"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="反馈时间"
                          prop="FeedbackTime">
              <el-date-picker v-model="popForm.createTime"
                              disabled
                              type="datetime"
                              class="dt-form-width"
                              placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否处理"
                          prop="HandleOrNot">
              <el-select v-model="popForm.feedbackStatusCode"
                         class="dt-form-width"
                         disabled
                         placeholder="请选择">
                <el-option v-for="item in stateList"
                           :key="Number(item.code)"
                           :label="item.name"
                           :value="Number(item.code)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="处理时间"
                          prop="ProcessingTime">
              <el-date-picker v-model="popForm.handlerTime"
                              disabled
                              class="dt-form-width"
                              type="datetime"
                              placeholder="">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="处理结果">
              <el-input v-model="popForm.handleResult"
                        :readonly=disabled
                        class="dt-form-width"
                        type="textarea"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="处理人"
                          prop="handler">
              <el-input v-model="popForm.handler"
                        readonly
                        class="dt-form-width"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="反馈图片">
            <div class="block">
              <el-col :span="8">
                <el-image v-show="popForm.picUrlType==0"
                          style="width: 200px; height: 200px"
                          :src="$imgBaseUrl + popForm.picUrl"
                          :preview-src-list="picsrcList"
                          fit="contain"></el-image>
                <video v-show="popForm.picUrlType==1"
                       style="width: 200px; height: 200px"
                       :src="$imgBaseUrl+popForm.picUrl"
                       controls="controls"></video>
              </el-col>
            </div>
          </el-form-item>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     v-show="disabled === false"
                     @click="popSubmit('popForm')"
                     style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">确 定</el-button>
          <el-button @click="dialogVisible = false"
                     type="info">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true },
      listMap: { pageNum: 1, pageSize: 15, },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableColumnList: [
        {
          prop: 'feedbackTypeDesc',
          label: '反馈类型',
        },
        {
          prop: 'content',
          label: '反馈描述',
        },
        {
          prop: 'concat',
          label: '联系方式',
        },
        {
          prop: 'createTime',
          label: '反馈时间',
        },
        {
          prop: 'feedbackStatusDesc',
          label: '是否处理',
        },
        {
          prop: 'handlerTime',
          label: '处理时间',
        },
      ],
      tableList: {
        list: [],
      },
      //查询表单
      searchForm: {},
      pageNum: 1,
      pageSize: 15,
      picsrcList: [],// 图片预览
      statusList: [],  //是否处理下拉框
      typeList: [], //意见类型下拉框
      stateList: [],   //意见状态
      noticeDate: [],//创建时间的数组
      popForm: {}, //修改详情
      feedBackForm: {
        handleResult: '',//处理反馈
      },
      dialogVisible: false,//弹出框显示
      rules: {
        handleResult: [
          { required: true, message: '请输入处理反馈信息', trigger: 'blur' },
        ],
      },
      feedForm: {},   //处理反馈表单
      feedBackDialogVisible: false,//意见反馈处理框
      disabled: false,//disabled的值
      dialogTitle: ""
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryTableList()
    this.getDict()
  },
  filters: {
    filteImg (url) {
      if (url) {
        return url.split("/").slice(-1)[0]
      } else {
        return '暂无图片'
      }
    }
  },
  //方法集合
  methods: {
     // 导出
    exportFile () {
      const loading = this.$loading({
        target: '#monitorBut',
        lock: false,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$axios.post(this.$downloadBaseUrl + "feedback/exportIn", this.searchForm , {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      }
      ).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "意见反馈表.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
        loading.close()
      })
    },
    // 查询，获取列表
    queryTableList () {
      this.searchForm["pageNum"] = this.pageNum
      this.searchForm["pageSize"] = this.pageSize
      this.$feedBackInfoManage.queryFeedBackList(this.searchForm).then((response) => {
        response.resultEntity.list.forEach(item => {
          if (item.picUrl) {
            if (item.picUrl.split('.')[1] === 'mp4') {
              item.picUrlType = "1"
            }
            else {
              item.picUrlType = "0"
            }
          }
        })
        this.tableList.list = response.resultEntity.list
        this.paginationConfig.total = response.resultEntity.total
      })
    },
    // 获取分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询
    queryButton () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      if (this.noticeDate) {
        this.searchForm.startTime = this.noticeDate[0]
        this.searchForm.endTime = this.noticeDate[1]
      }
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.searchForm = {}
      this.noticeDate = []
      this.queryTableList()
    },
    // 修改
    editClick (row) {
      this.dialogVisible = true
      this.dialogTitle = "修改反馈"
      this.popForm = this.$deepClone(row)
      if (row.feedbackStatusCode == 1) {
        this.disabled = true
      } else if (row.feedbackStatusCode == 2) {
        this.disabled = false
      }
    },
    //处理反馈意见提交
    feedbackSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$feedBackInfoManage.handleFeedBack(this.feedBackForm).then(() => {
            this.$message({ message: '处理成功', type: 'success' });
            this.queryTableList()
            this.feedBackDialogVisible = false
          })
        } else {
          return false;
        }
      });
    },
    //提交按钮
    popSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$feedBackInfoManage.updateFeedBack(this.popForm).then(() => {
            this.$message({ message: '修改成功', type: 'success' });
            this.queryTableList()
            this.dialogVisible = false
          })
        } else {
          return false;
        }
      });
    },
    // 修改请求
    requestUpdate () {
      console.log("up", this.popForm);
    },
    // 删除请求
    deleteClick (row) {
      this.$confirm('是否删除该条反馈?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$feedBackInfoManage.deleteFeedBack([{ feedbackId: row.feedbackId }]).then(() => {
          this.$message({ message: '删除成功', type: 'success' });
          this.queryTableList()
        })
      })
    },
    // 详情
    detailsClick (row) {
      this.picsrcList = []
      this.dialogTitle = "反馈详情"
      this.disabled = true
      this.popForm = row
      if (row.picUrl) {
        if (row.picUrl.split('.')[1] !== "mp4") {
          this.picsrcList.push(this.$imgBaseUrl + row.picUrl)
        }
      }
      this.dialogVisible = true
    },
    //处理按钮
    dealClick (row) {
      this.feedBackDialogVisible = true
      this.feedBackForm.feedbackId = row.feedbackId
    },
    // 清空验证
    popClose () {
      this.$refs.feedBackForm.resetFields();
    },
    //获取下拉菜单
    getDict () {
      // 状态
      let state = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'F8898BDD4BCF4W12ABA91DB035D54E3F',
          },
        ],
      }
      this.$queryDict.queryDict(state).then((res) => {
        this.stateList = res.resultEntity
      })
      //类型
      let type = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '4324HJKH3F316FDG3LK4H1234L12K313',
          },
        ],
      }
      this.$queryDict.queryDict(type).then((res) => {
        this.typeList = res.resultEntity
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      margin-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
  align-items: center;
}
</style>